import {http} from '@/plugins/axios/axios'

const getList = (params) => {
  return http.get('/v1/system-wallets', {
    params
  })
}

const getDetail = (systemWalletId) => {
  return http.get(`/v1/system-wallets/${systemWalletId}`)
}

const changeFee = (systemWalletId, payload) => {
  return http.patch(`/v1/system-wallets/${systemWalletId}/fees`, payload)
}

export default {
  getList,
  getDetail,
  changeFee
}
