import {http} from '@/plugins/axios/axios'

const getListTransactions = (params) => {
  return http.get('/v1/pools/transactions', {
    params
  })
}

const getPool = () => {
  return http.get('/v1/pools')
}

const editPool = (payload) => {
  return http.patch('/v1/pools', payload)
}

export default {
  getListTransactions,
  getPool,
  editPool,
}
