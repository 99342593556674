<template>
  <div class='transaction-page'>
    <a-table :columns='columns' :data-source='listUsers' row-key='phaseId' :loading='loading' :scroll='{ x: 1000}'
             :pagination="{
      current: query.page,
      pageSize: query.size,
      total: total
             }" @change="onChangeTable">
      <template slot='stt' slot-scope='item, data, index'> {{ (query.page - 1) * query.size + index + 1 }}</template>
      <template slot='txHash' slot-scope='item'>
        <a-icon type='copy' class='mr-10 ic-copy' @click='copyURL(item.txHash)' />
        <span>{{item.txHash}}</span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { copyURL} from '@/helpers/common'
import PoolService from "@/services/app/pool.service";

export default {
  name: 'TransactionsWithDrawList',
  data() {
    return {
      query: {
        page: 1,
        size: 10,
        action: 'withdraw'
      },
      timeout: null,
      listUsers: [],
      total: 0,
      loading: false,
      columns: [
        {
          title: 'Order',
          width: 80,
          key: 'stt',
          scopedSlots: { customRender: 'stt' }
        },
        {
          title: 'Phase ID',
          key: 'phaseId',
          dataIndex: 'phaseId',
          width: 100,
        },
        {
          title: 'TX Hash',
          scopedSlots: { customRender: 'txHash' },
          width: 300,
          ellipsis: true,
          key: 'txHash',
        },
        {
          title: 'Pool ID',
          key: 'poolId',
          dataIndex: 'poolId',
          width: 140,
        },
        {
          title: 'Time',
          width: 200,
          dataIndex: 'time',
          key: 'time',
        },
      ],
    }
  },
  created() {
    this.setBreadcrumb(this.breadcrumb)
  },
  mounted() {
    this.onFetchData()
  },

  methods: {
    copyURL,
    async onFetchData() {
      this.loading = true
      const response = await PoolService.getListTransactions(this.query)
      if (response.code === 1) {
        this.listUsers = response.data || []
        this.total = response.totalElement || 0
      }
      this.loading = false
    },
    onChangeFilter() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.query.page = 1
        this.onFetchData();
      }, 800);
    },
    onChangeTable(val) {
      this.query.page = val.current
      this.onFetchData()
    },
  }
}
</script>

<style scoped lang="scss">

</style>