import {http} from '@/plugins/axios/axios'

const getListWalletAddressHistory = (systemWalletId) => {
  return http.get(`/v1/system-wallets/${systemWalletId}/histories`)
}

const getWalletTransactionLogs12 = (params) => {
  return http.get('/v1/system-wallets/transactions/spend', {
    params
  })
}

const getWalletTransactionLogs34 = (params) => {
  return http.get('/v1/system-wallets/transactions/receive-fees', {
    params
  })
}

const getWalletTransactionLogs34Minus = (params) => {
  return http.get('/v1/system-wallets/transactions/jackpot-rewards', {
    params
  })
}

export default {
  getListWalletAddressHistory,
  getWalletTransactionLogs12,
  getWalletTransactionLogs34,
  getWalletTransactionLogs34Minus
}
