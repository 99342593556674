import {notification} from 'ant-design-vue'
export function formatDate(date) {
    return date.split('-')[1] + '-' + date.split('-')[0] + '-'+  date.split('-')[2];
}

export function numberWithCommas(x) {
    if (!x) {
        return
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function copyURL(url) {
    if (!url) {
        return
    }
    const selBox = document.createElement('textarea');
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    notification.success({
        message: 'Copied to clipboard'
    })
}

export function exportExcel(data, fileName) {
    console.log(data)
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob(data));
    link.download = fileName;
    link.click();
}

export function randomName(length) {
    return Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));
}
